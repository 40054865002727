<template>
    <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
        <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                <div>
                    <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                    <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->

                    <Dropdown id="userid" dataKey="userid" v-model="filters.userid" :loading="loadingDropdownUser"
                        :options="dataDropdownUser"
                        optionLabel="label" optionValue="userid" placeholder="Select User"
                        :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')" />
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                </span>
            </div>
        </template>
        <Column field="number" header="No." :style="{width:'20px'}" >
            <template #body="slotProps">
            {{ slotProps.data.number }}
            </template>
        </Column>
        <Column field="username" header="SR Code">
            <template #body="slotProps">
            {{ slotProps.data.username }}
            </template>
        </Column>
        <Column field="name" header="SR Name">
            <template #body="slotProps">
            {{ slotProps.data.name }}
            </template>
        </Column>
        <Column field="territory" header="Territory">
            <template #body="slotProps">
            {{ slotProps.data.territory }}
            </template>
        </Column>
        <Column field="outlet_visit" header="Outlet Visits (TC)">
            <template #body="slotProps">
            {{ slotProps.data.outlet_visit }}
            </template>
        </Column>
        <Column field="billed_outlet" header="Billed Outlet (PC)">
            <template #body="slotProps">
            {{ slotProps.data.billed_outlet }}
            </template>
        </Column>
        <Column field="sales_value" header="Sales Value">
            <template #body="slotProps">
            {{ slotProps.data.sales_value }}
            </template>
        </Column>
        <Column field="working_days" header="Working days  (Mandays)">
            <template #body="slotProps">
            {{ slotProps.data.working_days }}
            </template>
        </Column>
        <template #empty>
            No data found.
        </template>
        <template #loading>
            Loading data. Please wait.
        </template>
        <template #footer>
            In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
        </template>
    </DataTable>

    <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
        <template>
            {{totalItemsCount}}
        </template>
    </Paginator>
</template>

<script>

import moment from "moment";
import { numberingDatatable } from "../../utils/helpers";

export default {
    // props: [
	// 	"filter",
    //     "period"
    // ],
    data() {
        return {
            loading: false,
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownUser: null,

            // filter
            filters: {
                userid: null,
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.searchDropdownUser('');
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        filters: {
            handler() {
                setTimeout(() => {
                    this.getDataTable();
                }, 500);
            },
            deep: true
        },
        // filter: {
        //     handler() {
        //         setTimeout(() => {
        //             this.getDataTable();
        //         }, 500);
        //     },
        //     deep: true
        // },
        // period: {
        //     handler() {
        //         setTimeout(() => {
        //             this.getDataTable();
        //         }, 500);
        //     },
        // }
    },
    computed:{
    },
    methods:{
        formatDate(value) {
            if (value) {
                return moment.utc(value).format("DD-MM-YYYY");
            }
        },
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.duser.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/mcu2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;
                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/summary',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "userid" : this.filters.userid
                    // "distributor": this.filter.dist_code,
                    // "periode": this.period,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
    }
}
</script>